var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"questions authoring-wrapper"},[_c('div',{staticClass:"flex"},[_c('ProgressBar',{attrs:{"stepIndex":2,"surveyID":_vm.surveyID,"stepsType":"map-maker"}}),_c('section',{staticClass:"wrapper"},[_c('a-breadcrumb',{staticClass:"breadcrum breadcrum--authoring"},[(_vm.user !== null && _vm.user.SkinIdentifier !== 'pg')?[_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/"}},[_vm._v(_vm._s(_vm.client.ClientName))])]),_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/products"}},[_vm._v("Products")])]),_c('a-breadcrumb-item',[_vm._v("Map Maker Test")])]:_vm._e()],2),_c('div',{staticClass:"wrapper__inner"},[_c('div',{staticClass:"page-title page-title--transparent-svg-color"},[_c('icon-base',{attrs:{"width":32,"height":33,"viewBox1":24,"viewBox2":24,"iconName":"category-inputs","iconColor":"#4318FF"}},[_c('icon-category-inputs')],1),_vm._m(0)],1),_c('div',{staticClass:"box-wrapper mb-25"},[_vm._m(1),_c('p',{staticClass:"category-inputs__info"},[_vm._v(" Please enter your brand list. These brands will be used for awareness and you can also select the brands you want maps for at the next screen. You can enter between 1-8 brands. ")]),_c('div',{staticClass:"category-inputs"},[_c('div',{staticClass:"ant-input-group-addon-small input-group mb-15"},_vm._l((_vm.categoryInputs),function(option,index){return _c('div',{key:index,staticClass:"position-r"},[(_vm.loadingSurveyData)?_c('Skeleton',{attrs:{"borderRadius":16}}):_vm._e(),_c('a-input',{staticClass:"options-input",attrs:{"placeholder":"Brand, for eg. 'Crest'"},on:{"pressEnter":function($event){return _vm.addCategoryInputs(index)}},model:{value:(_vm.categoryInputs[index].brands),callback:function ($$v) {_vm.$set(_vm.categoryInputs[index], "brands", $$v)},expression:"categoryInputs[index].brands"}},[(
                      option.brands === '' ||
                      (!_vm.categoryInputs[index + 1] &&
                        index + 1 < _vm.maxCategoryInputs)
                    )?_c('a-button',{attrs:{"slot":"addonAfter"},on:{"click":function($event){return _vm.addCategoryInputs(index)}},slot:"addonAfter"},[_vm._v(" Add ")]):_c('a-button',{staticClass:"ant-btn--delete",attrs:{"slot":"addonAfter"},on:{"click":function($event){return _vm.removeCategoryInputs(index)}},slot:"addonAfter"},[_vm._v(" Delete ")])],1)],1)}),0),_c('a-button',{staticClass:"category-inputs__bulk-add",attrs:{"type":"primary"},on:{"click":function($event){_vm.bulkAddModal = true}}},[_vm._v(" Bulk Add ")])],1)]),_c('div',{staticClass:"box-wrapper"},[_c('div',{staticClass:"mb-40"},[_vm._m(2),_c('a-button',{staticClass:"mb-40",class:{ dlb: _vm.dlbStatus },attrs:{"type":"primary","disabled":(_vm.customQuestionsList &&
                  _vm.customQuestionsList.CustomQuestions.length >= 6) ||
                _vm.loading},on:{"click":function($event){_vm.dlbStatus
                  ? (_vm.addCustomQuestionModal = false)
                  : (_vm.addCustomQuestionModal = true)}}},[_vm._v(" Add question ")]),_c('h2',[_vm._v("Questions preview")]),_c('div',{staticClass:"questions__box"},[(
                  _vm.customQuestionsList !== null &&
                  _vm.customQuestionsList.CustomQuestions.length
                )?_c('QuestionsListPreview',{attrs:{"questionsList":_vm.customQuestionsList.CustomQuestions,"langObj":_vm.langObj,"questionSettings":true,"loading":_vm.questionsConfig.loadingNewQuestions},on:{"deleteCustomQuestions":_vm.deleteCustomQuestions,"editQuestion":_vm.editQuestion,"setAsScreener":_vm.setAsScreener}}):_c('p',{staticClass:"font-size-14 mb-0"},[(_vm.loading)?_c('Loader',{attrs:{"text":"Loading custom question..."}}):_c('span',[_vm._v("You don't have any custom questions yet.")])],1)],1),(
                _vm.customQuestionsList !== null &&
                _vm.customQuestionsList.CustomQuestions.length &&
                !_vm.tempHide
              )?_c('div',[_c('h2',[_vm._v("Instructions")]),_c('div',{staticClass:"questions__box"},[_c('label',[_vm._v(" Do you have any requirements for the custom question(s)? Please describe any logic, termination, dependencies or piping that may be required. ")]),_c('a-textarea',{attrs:{"auto-size":{ minRows: 4 }},on:{"blur":function($event){return _vm.insertCustomQuestions(_vm.customQuestionsList)}},model:{value:(_vm.customQuestionsList.questionsInstructions),callback:function ($$v) {_vm.$set(_vm.customQuestionsList, "questionsInstructions", $$v)},expression:"customQuestionsList.questionsInstructions"}})],1)]):_vm._e()],1)]),_c('StepNaviBar',{attrs:{"stepIndex":4,"conciergeAssistance":true,"nextDisable":!_vm.enableNextStep,"prevDisable":false,"loadingNextBtn":_vm.loadingSaveBrands,"prevBtnTooltip":"Back to Brand Strategy","nextBtnTooltip":"Go to Launch","stepsType":"map-maker-test"},on:{"nextHandler":_vm.nextStep,"prevHandler":_vm.prevStep,"loading-survey-data":function($event){_vm.loadingSurveyData = $event},"editable-survey-updated":_vm.handleEditableSurveyUpdate}})],1)],1)],1),_c('a-modal',{attrs:{"title":"Bulk Add ","visible":_vm.bulkAddModal,"cancelText":"Cancel","okText":"Add"},on:{"ok":_vm.processBrands,"cancel":function($event){_vm.bulkAddModal = false}}},[_c('div',{staticClass:"input-group position-r"},[_c('label',[_vm._v("Please copy and paste your full list with up to 1-8 brands below. Make sure you paste them in a vertical list format, with one brand per row.")]),_c('a-textarea',{attrs:{"auto-size":{ minRows: 8 }},model:{value:(_vm.brandsText),callback:function ($$v) {_vm.brandsText=$$v},expression:"brandsText"}}),(_vm.errorMessage)?_c('div',{staticClass:"validation"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)]),_c('a-modal',{attrs:{"title":_vm.questionsModalTitle,"visible":_vm.addCustomQuestionModal,"width":_vm.questionsConfig.editMode ? 650 : 800,"okText":"Save","okButtonProps":{
      props: { disabled: !_vm.questionsData.CustomQuestions.length },
    },"footer":null,"destroyOnClose":true,"wrapClassName":"ant-modal--custom-questions"},on:{"cancel":function($event){return _vm.closeQuestionBuilder('cancel')}}},[(!_vm.questionsConfig.editMode)?_c('div',{staticClass:"choose-questions-nav"},[_c('a-button',{attrs:{"type":_vm.questionsConfig.activeMode === 'builder' ? 'primary' : ''},domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalTab1)},on:{"click":function($event){(_vm.questionsConfig.activeMode = 'builder'),
            (_vm.questionsConfig.activeBuilder = _vm.questionsConfig.type)}}}),_c('a-button',{attrs:{"type":_vm.questionsConfig.activeMode === 'examples' ? 'primary' : ''},domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalTab2)},on:{"click":function($event){(_vm.questionsConfig.activeMode = 'examples'),
            (_vm.questionsConfig.activeBuilder = null)}}})],1):_vm._e(),_c('div',{staticClass:"choose-questions",class:{ 'choose-questions--edit': _vm.questionsConfig.editMode }},[(!_vm.questionsConfig.editMode)?_c('div',{staticClass:"choose-questions__types"},[_c('a-radio-group',{class:{
            'ant-radio-group--border-b': _vm.questionsData.CustomQuestions.length,
            'ant-radio-group--disabled':
              _vm.questionsConfig.previewQuestionsList ||
              _vm.questionsConfig.editMode,
          },attrs:{"disable":""},model:{value:(_vm.questionsConfig.type),callback:function ($$v) {_vm.$set(_vm.questionsConfig, "type", $$v)},expression:"questionsConfig.type"}},[_c('a-radio',{style:(_vm.questionsConfig.radioBtnTypesStyle),attrs:{"selected":"","value":"text"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalQuestionType1)}})]),_c('a-radio',{style:(_vm.questionsConfig.radioBtnTypesStyle),attrs:{"selected":"","value":"essay"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalQuestionType6)}})]),_c('a-radio',{style:(_vm.questionsConfig.radioBtnTypesStyle),attrs:{"value":"radio"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalQuestionType2)}})]),_c('a-radio',{style:(_vm.questionsConfig.radioBtnTypesStyle),attrs:{"value":"checkbox"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalQuestionType3)}})]),_c('a-radio',{style:(_vm.questionsConfig.radioBtnTypesStyle),attrs:{"value":"rank-dragdrop"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalQuestionType4)}})]),_c('a-radio',{style:(_vm.questionsConfig.radioBtnTypesStyle),attrs:{"value":"table-radio"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalQuestionType7)}})]),_c('a-radio',{style:(_vm.questionsConfig.radioBtnTypesStyle),attrs:{"value":"table-checkbox"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalQuestionType5)}})])],1)],1):_vm._e(),_c('div',{staticClass:"choose-questions__preview"},[[(_vm.questionsConfig.type === 'text')?_c('QuestionsTypePreview',{attrs:{"title":_vm.langObj['s4-customQuestions'].modalQuestionType1,"subTitle":_vm.langObj['s4-customQuestions'].modalTextQIntro,"exampleQuestionText":_vm.langObj['s4-customQuestions'].modalTextQExampleLabel,"description":_vm.langObj['s4-customQuestions'].modalTextQDetailsLabel,"builderMode":_vm.questionsConfig.type === 'text' &&
              _vm.questionsConfig.activeBuilder === 'text'
                ? true
                : false,"editMode":_vm.questionsConfig.editMode,"langObj":_vm.langObj}},[(
                _vm.questionsConfig.type === 'text' &&
                _vm.questionsConfig.activeBuilder === 'text'
              )?_c('TextTypeBuilder',{attrs:{"editMode":_vm.questionsConfig.editMode,"questionInfo":_vm.customQuestionsList,"questionIndex":_vm.questionsConfig.editModeItemIndex,"langObj":_vm.langObj},on:{"question-data":_vm.setQuestionData,"question-builde:close":_vm.closeQuestionBuilder}}):_c('TextTypeExample',{attrs:{"exampleQuestionText":_vm.langObj['s4-customQuestions'].modalTextQExampleLabel,"langObj":_vm.langObj,"questionSettings":false}})],1):_vm._e(),(_vm.questionsConfig.type === 'essay')?_c('QuestionsTypePreview',{attrs:{"title":_vm.langObj['s4-customQuestions'].modalQuestionType6,"subTitle":_vm.langObj['s4-customQuestions'].modalEssayQIntro,"exampleQuestionText":_vm.langObj['s4-customQuestions'].modalEssayQExampleLabel,"description":_vm.langObj['s4-customQuestions'].modalEssayQDetailsLabel,"builderMode":_vm.questionsConfig.type === 'essay' &&
              _vm.questionsConfig.activeBuilder === 'essay'
                ? true
                : false,"editMode":_vm.questionsConfig.editMode,"langObj":_vm.langObj}},[(
                _vm.questionsConfig.type === 'essay' &&
                _vm.questionsConfig.activeBuilder === 'essay'
              )?_c('TextTypeBuilder',{attrs:{"editMode":_vm.questionsConfig.editMode,"questionInfo":_vm.customQuestionsList,"questionIndex":_vm.questionsConfig.editModeItemIndex,"langObj":_vm.langObj,"type":"essay"},on:{"question-data":_vm.setQuestionData,"question-builde:close":_vm.closeQuestionBuilder}}):_c('TextTypeExample',{attrs:{"exampleQuestionText":_vm.langObj['s4-customQuestions'].modalEssayQExampleLabel,"langObj":_vm.langObj,"questionSettings":false,"type":"essay"}})],1):_vm._e(),(_vm.questionsConfig.type === 'radio')?_c('QuestionsTypePreview',{attrs:{"title":_vm.langObj['s4-customQuestions'].modalQuestionType2,"subTitle":_vm.langObj['s4-customQuestions'].modalSingleQIntro,"description":_vm.langObj['s4-customQuestions'].modalSingleQDetailsLabel,"builderMode":_vm.questionsConfig.type === 'radio' &&
              _vm.questionsConfig.activeBuilder === 'radio'
                ? true
                : false,"editMode":_vm.questionsConfig.editMode,"langObj":_vm.langObj}},[(
                _vm.questionsConfig.type === 'radio' &&
                _vm.questionsConfig.activeBuilder === 'radio'
              )?_c('MultiOptionsTypeBuilder',{attrs:{"editMode":_vm.questionsConfig.editMode,"questionInfo":_vm.customQuestionsList,"questionIndex":_vm.questionsConfig.editModeItemIndex,"type":"radio","sort":"NONE","langObj":_vm.langObj},on:{"question-data":_vm.setQuestionData,"question-builde:close":_vm.closeQuestionBuilder}}):_c('SingleChoiceExample',{attrs:{"exampleQuestionText":_vm.langObj['s4-customQuestions'].modalSingleQExampleLabel}})],1):_vm._e(),(_vm.questionsConfig.type === 'checkbox')?_c('QuestionsTypePreview',{attrs:{"title":_vm.langObj['s4-customQuestions'].modalQuestionType3,"subTitle":_vm.langObj['s4-customQuestions'].modalMultiQIntro,"description":_vm.langObj['s4-customQuestions'].modalMultiQDetailsLabel,"builderMode":_vm.questionsConfig.type === 'checkbox' &&
              _vm.questionsConfig.activeBuilder === 'checkbox'
                ? true
                : false,"editMode":_vm.questionsConfig.editMode,"langObj":_vm.langObj}},[(
                _vm.questionsConfig.type === 'checkbox' &&
                _vm.questionsConfig.activeBuilder === 'checkbox'
              )?_c('MultiOptionsTypeBuilder',{attrs:{"editMode":_vm.questionsConfig.editMode,"questionInfo":_vm.customQuestionsList,"questionIndex":_vm.questionsConfig.editModeItemIndex,"type":"checkbox","sort":"SHUFFLE","langObj":_vm.langObj},on:{"question-data":_vm.setQuestionData,"question-builde:close":_vm.closeQuestionBuilder}}):_c('MultiChoiceExample',{attrs:{"exampleQuestionText":_vm.langObj['s4-customQuestions'].modalMultiQExampleLabel}})],1):_vm._e(),(_vm.questionsConfig.type === 'rank-dragdrop')?_c('QuestionsTypePreview',{attrs:{"title":_vm.langObj['s4-customQuestions'].modalQuestionType4,"subTitle":_vm.langObj['s4-customQuestions'].modalRankQIntro,"description":_vm.langObj['s4-customQuestions'].modalRankQDetailsLabel,"builderMode":_vm.questionsConfig.type === 'rank-dragdrop' &&
              _vm.questionsConfig.activeBuilder === 'rank-dragdrop'
                ? true
                : false,"editMode":_vm.questionsConfig.editMode,"langObj":_vm.langObj}},[(
                _vm.questionsConfig.type === 'rank-dragdrop' &&
                _vm.questionsConfig.activeBuilder === 'rank-dragdrop'
              )?_c('MultiOptionsTypeBuilder',{attrs:{"editMode":_vm.questionsConfig.editMode,"questionInfo":_vm.customQuestionsList,"questionIndex":_vm.questionsConfig.editModeItemIndex,"type":"rank-dragdrop","sort":"SHUFFLE","langObj":_vm.langObj},on:{"question-data":_vm.setQuestionData,"question-builde:close":_vm.closeQuestionBuilder}}):_c('RankExample',{attrs:{"exampleQuestionText":_vm.langObj['s4-customQuestions'].modalRankQExampleLabel}})],1):_vm._e(),(_vm.questionsConfig.type === 'table-radio')?_c('QuestionsTypePreview',{attrs:{"title":_vm.langObj['s4-customQuestions'].modalQuestionType7,"subTitle":_vm.langObj['s4-customQuestions'].modalGridQIntro,"description":_vm.langObj['s4-customQuestions'].modalGridQDetailsLabel,"builderMode":_vm.questionsConfig.type === 'table-radio' &&
              _vm.questionsConfig.activeBuilder === 'table-radio'
                ? true
                : false,"editMode":_vm.questionsConfig.editMode,"langObj":_vm.langObj}},[(
                _vm.questionsConfig.type === 'table-radio' &&
                _vm.questionsConfig.activeBuilder === 'table-radio'
              )?_c('GridTypeBuilder',{attrs:{"editMode":_vm.questionsConfig.editMode,"questionInfo":_vm.customQuestionsList,"questionIndex":_vm.questionsConfig.editModeItemIndex,"langObj":_vm.langObj},on:{"question-data":_vm.setQuestionData,"question-builde:close":_vm.closeQuestionBuilder}}):_c('GridExample',{attrs:{"langObj":_vm.langObj,"exampleQuestionText":_vm.langObj['s4-customQuestions'].modalGridQExampleLabel,"type":"table-radio"}})],1):_vm._e(),(_vm.questionsConfig.type === 'table-checkbox')?_c('QuestionsTypePreview',{attrs:{"title":_vm.langObj['s4-customQuestions'].modalQuestionType5,"subTitle":_vm.langObj['s4-customQuestions'].modalGridCheckboxQIntro,"description":_vm.langObj['s4-customQuestions'].modalGridCheckboxQDetailsLabel,"builderMode":_vm.questionsConfig.type === 'table-checkbox' &&
              _vm.questionsConfig.activeBuilder === 'table-checkbox'
                ? true
                : false,"editMode":_vm.questionsConfig.editMode,"langObj":_vm.langObj}},[(
                _vm.questionsConfig.type === 'table-checkbox' &&
                _vm.questionsConfig.activeBuilder === 'table-checkbox'
              )?_c('GridTypeBuilder',{attrs:{"editMode":_vm.questionsConfig.editMode,"questionInfo":_vm.customQuestionsList,"questionIndex":_vm.questionsConfig.editModeItemIndex,"langObj":_vm.langObj,"type":"table-checkbox"},on:{"question-data":_vm.setQuestionData,"question-builde:close":_vm.closeQuestionBuilder}}):_c('GridExample',{attrs:{"langObj":_vm.langObj,"exampleQuestionText":_vm.langObj['s4-customQuestions'].modalGridCheckboxQExampleLabel}})],1):_vm._e(),(_vm.questionsConfig.activeBuilder === null)?_c('div',{staticClass:"flex justify-center"},[_c('a-button',{attrs:{"type":"primary"},domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalButton5)},on:{"click":_vm.setBuilderType}})],1):_vm._e()]],2)])]),_c('CompletionProgress',{attrs:{"checkList":_vm.quizs}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title__text"},[_c('h1',[_vm._v("Category Inputs")]),_c('span',[_vm._v("Add custom inputs for your study")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-info"},[_c('p',[_vm._v(" If you have additional metrics and/or screeners that are specific to your category, you can tailor "),_c('span',{staticClass:"red"},[_vm._v("custom inputs")]),_vm._v(" for your study. You can add up to "),_c('span',{staticClass:"red"},[_vm._v("6 custom questions and 1-8 brands.")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-info"},[_c('p',[_vm._v("Add up to "),_c('span',{staticClass:"red"},[_vm._v("6 custom questions.")])])])}]

export { render, staticRenderFns }